import '@css/main.scss'
import '@js/bootstrap'
//import SimpleLightbox from 'simplelightbox'

import { Header } from '@js/components/Header'
import { Slider } from '@js/components/Slider'
import { Hero } from '@js/components/Hero'
import { Animation } from '@js/utils/Animation'
import { Archive } from '@js/components/Archive'
import { Accordion } from '@js/components/Accordion'

window.onload = () => {

  new Animation()
  new Hero()

  if (document.querySelector('*[data-archive]')) {
    new Archive()
  }
  new Header()
  new Slider()
  new Accordion()

}
