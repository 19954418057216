export class Cloner {

  constructor(type, clone, item) {
    type = type.charAt(0).toUpperCase() + type.slice(1)
    this.post = this[`clone${type}`](clone, item)
  }

  clonePost(clone, item) {

    const thumb = clone.querySelector('.c-post__img');
    const title = clone.querySelector('.c-post h3');
    const date = clone.querySelector('.c-post-content__date');
    const links = clone.querySelectorAll('.c-post a');
    const category = clone.querySelector('.c-post-content__category')
    const excerpt = clone.querySelector('.c-post-content__excerpt')

    const thumbnail = document.createElement('img')
    thumbnail.setAttribute('src', item.thumbnail)

    thumb.appendChild(thumbnail)

    if (item.post_title) {
      title.innerHTML = item.post_title

    }

    if (item.date) {
      date.innerHTML = item.date
    }

    if (item.name) {
      category.innerHTML = item.name
    }

    if (links) {
      links.forEach((link) => {
        link.setAttribute('href', item.link)
      });
    }

    if (item.excerpt) {
      excerpt.innerHTML = item.excerpt
    }

    return clone

  }

  cloneProject(clone, item) {

    const thumb = clone.querySelector('.c-project__img');
    const category = clone.querySelector('.c-project__category')
    const links = clone.querySelectorAll('.c-project a');

    const thumbnail = document.createElement('img')
    thumbnail.setAttribute('src', item.thumbnail)

    thumb.appendChild(thumbnail)

    if (item.name) {
      category.innerHTML = item.name
    }

    if (links) {
      links.forEach((link) => {
        link.setAttribute('href', item.link)
      });
    }

    return clone

  }

}
