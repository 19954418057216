import { gsap } from 'gsap'
import { Cloner } from '@js/utils/Cloner'

export class Archive {
  constructor () {

    this.container = document.querySelector('*[data-archive]')
    if (!this.container) {
      return
    }

    this.type = this.container.getAttribute('data-archive')

    this.filters_container = this.container.querySelector('*[data-filters]')
    this.filters_type = this.filters_container.getAttribute('data-filters')
    this.filters = this.filters_container.querySelectorAll('*[data-filter]')
    this.itemsContainer = this.container.querySelector('.s-blog-grid')
    this.loader = this.container.querySelector('.loader')
    this.init()
  }

  init () {
    this.config = this.defaults()
    this.url = `${window.location.protocol}//${window.location.hostname}/wp-json/akyos/v1/${this.type}`
    this.register()
    this.filterAnimation()
  }

  defaults () {
    return {
      noMoreItems: false,
      currentTax: false,
    }
  }

  reset () {
    this.filters.forEach(f => f.classList.remove('active'))
    this.config = this.defaults()
    this.itemsContainer.innerHTML = ''
  }

  register () {
    this.filters.forEach(f => {
      f.addEventListener('click', (e) => {
        const id = f.dataset.filter
        e.preventDefault()
        this.reset()
        f.classList.add('active')
        this.config.currentTax = id
        this.fetch(true)
      })
    })
  }

  fetch (reset = false) {

    this.loader.style.display = 'flex'

    const params = new URLSearchParams()
    if (this.config.currentTax && this.config.currentTax !== '*') {
      params.append(this.filters_type, this.config.currentTax)
    }

    fetch(`${this.url}?${params.toString()}`)
      .then((r) => r.json())
      .then(r => {

        console.log(r)

        if (reset) {
          this.itemsContainer.innerHTML = ''
        }

        if (r.length === 0) {
          this.itemsContainer.innerHTML = '<p id="no-result">Aucun résultat trouvé</p>'
          this.config.noMoreItems = true
        } else {
          this.appendItems(r)
        }

        this.loader.style.display = 'none'

      })
      .catch(r => {
        console.log(r)
        this.config.noMoreItems = true
      })
  }

  appendItems (items) {

    const template = document.querySelector('#archive-el')

    items.forEach((item) => {
      let clone = template.content.cloneNode(true)
      clone = new Cloner(this.type, clone, item)
      this.itemsContainer.appendChild(clone.post)
    })

  }

  filterAnimation () {
    const filters = document.querySelectorAll('.s-blog-filters__li')
    const indicator = document.getElementById('active-filter')

    let init = false

    function moveIndicator (target) {

      let newLeft = target.offsetLeft + (target.offsetWidth / 2) - (indicator.offsetWidth / 2)

      // if (target.dataset.filter === '*' && !init) {
      //     newLeft -= (target.offsetWidth / 2) + 5
      //     init = true
      // }

      gsap.to(indicator, {
        left: newLeft + 'px',
        duration: 0.4,
        ease: 'power2.out'
      })
    }

    filters.forEach(filter => {
      filter.addEventListener('click', function () {
        moveIndicator(this)
      })
    })

    setTimeout(() => {
      moveIndicator(document.querySelector('.s-blog-filters__li.active'))
    }, 500)

    window.addEventListener('resize', function () {
      moveIndicator(document.querySelector('.s-blog-filters__li.active'))
    })

  }

}

