import { gsap } from 'gsap'
import Aos from 'aos'

export class Animation {

  constructor () {

    if (localStorage.getItem('heroAnimation') === 'played') {
      this.transition()
    } else {
      const overlay = document.getElementById('transition-overlay')
      overlay.style.display = 'none'
    }

    Aos.init({
        easing: 'ease-out',
        offset: 250,
        once: true
      }
    )
  }

  transition () {

    const overlay = document.getElementById('transition-overlay')
    const links = document.querySelectorAll('a:not(.images-grid a):not(.c-project a):not(.menu-item-has-children > a)')

    links.forEach(link => {
      link.addEventListener('click', function (event) {

        const url = this.href

        if (url.startsWith(window.location.origin)) {
          event.preventDefault()
          gsap.to(overlay, {
            x: 0,
            duration: 1,
            ease: 'power2.inOut',
            onComplete: () => {
              window.location.href = url
            }
          })
        }
      })
    })

    window.addEventListener('pageshow', function () {

      let duration = 1
      let delay = 0

      if (document.querySelector('.home')) {
        delay = 0.6
      }

      gsap.fromTo(overlay,
        { x: 0 },
        {
          x: '100%',
          duration: duration,
          ease: 'power2.inOut',
          delay: delay
        }
      )
    })

  }

}
