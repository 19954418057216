import { gsap } from 'gsap'

export class Hero {
    constructor()
    {
        this.animation()
    }

    animation()
    {

        let hero = document.querySelector('.s-hero')

        if (hero) {
            this.playVideo()

            let tl = gsap.timeline({ defaults: { duration: 0.7, ease: 'power2.out' } })
            tl.from('.s-hero__title span:first-child', { y: -50, opacity: 0 })
            tl.from('.s-hero__title span:last-child', { y: 50, opacity: 0 })
            tl.from('.s-hero__subtitle p:first-child', { y: -100, opacity: 0 })
            tl.from('.s-hero__subtitle p:last-child', { y: 50, opacity: 0 })
            tl.from('.s-hero-scroll', { y: -100, opacity: 0 })
            tl.from('.s-hero-scroll .line', { height: 0, duration: 0.5, ease: 'power' })
            tl.from('.s-hero__video', { opacity: 0, duration: 1 }, '-=1')
            tl.from('.s-hero__image', { opacity: 0, duration: 1 }, '-=1')

            let line = document.querySelector('.s-hero-scroll .line')
            let tl2 = gsap.timeline({ repeat: -1 })
            tl2.to(line, { opacity: 0, duration: 1 })
            tl2.to(line, { opacity: 1, duration: 1 })
        }

    }

    playVideo()
    {
        setTimeout(() => {
            let video = document.querySelector('.s-hero__video iframe')
            if (video) {
                let videoId = video.src.split('embed/')[1].split('?')[0]
                if (video) {
                    video.src += '&autoplay=1&mute=1&loop=1&playlist=' + videoId
                }
            }

        }, 250)
    }

}
